<template>
  <div class="autonomous_system">
    <headerNav pathname="自主系统" />
    <text-common title="服务支持" :img="img" />
    <div class="autonomous_system_center_bg">
      <theme title="自主系统" />

      <!-- 第一行自主内容 -->
      <div class="autonomous_system_center">
        <img
          width="489px"
          height="385px"
          src="../assets/autonomousSystem/notebook.png"
          alt=""
        />
        <div class="autonomous_system_text">
          <div class="title">鲲鹏物流综合运输系统</div>
          <div class="content">
            作为拥有几十年专业物流运输积淀的龙头公司，我们致力于打造信息化、智能化公铁联运平台，服务大众的同时与时俱进，公司全部职员同时在自主研发的内部系统内协同工作。
            专业研发工程部门开发出了“鲲鹏”综合物流运输系统，保障每一位客户的安全、高质量货运期望。
          </div>
        </div>
      </div>

      <!-- 第二行自主内容 -->
      <div class="autonomous_system_center">
        <div class="autonomous_system_text_two">
          <div class="title">驮龙物流 客户移动端程序</div>
          <div class="content">
            作为拥有几十年专业物流运输积淀的龙头公司，我们致力于打造信息化、智能化公铁联运平台，服务大众的同时与时俱进，公司全部职员同时在自主研发的内部系统内协同工作。
            操作更加便捷、功能更加全面的移动端程序，为实现科学排班、精准时效管理等公司价值搭建了平台。
          </div>
        </div>
        <div class="imgPosition">
          <img
            class="ellipse_right"
            width="424px"
            height="316px"
            src="../assets/autonomousSystem/ellipse_right.png"
            alt=""
          />
          <img
            class="phone"
            width="577px"
            height="481px"
            src="../assets/autonomousSystem/phone.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <bottom-navigation />
  </div>
</template>

<script>
export default {
  data () {
    return {
      img: require("../assets/service_support/service_support_bg.png")
    }
  }
}
</script>

<style lang="less" scoped>
.autonomous_system {
  .autonomous_system_center_bg {
    padding: 80px 0;
    box-sizing: border-box;
    background: #f0f2f5;
    color: rgba(0, 0, 0, 0.85);
    .autonomous_system_center {
      display: flex;
      justify-content: center;
      font-size: 14px;
      margin-top: 110px;
      .autonomous_system_text {
        width: 519px;
        height: 385px;
        padding: 100px 0 0 53px;
        box-sizing: border-box;
      }
      .title {
        font-size: 20px;
        margin-bottom: 19px;
        font-weight: bold;
      }
      .content {
        line-height: 25px;
      }
      // 第二行内容样式
      .autonomous_system_text_two {
        width: 580px;
        height: 385px;
        // margin-top: 133px;
        padding: 150px 0 0 120px;
        box-sizing: border-box;
      }
      .imgPosition {
        width: 577px;
        height: 481px;
        position: relative;
        .ellipse_right {
          position: absolute;
          top: 93px;
          left: 80px;
        }
        .phone {
          position: absolute;
          top: 0;
        }
      }
    }
  }
}
</style>